<div class="header">
  <!-- Router link here is default ["/"] -->
  <spb-logo title="Tjänsteportalen"></spb-logo>
  <div [style.flex-grow]="1"></div>
  <div *ngIf="(configService.logInState$ | async) as user" class="menu">
    <span>{{user.name}}</span>
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>